/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

require('./src/styles/global.scss');

// Wraps every page in a component
exports.wrapPageElement = ({ element, props }) => {};
