import { useStaticQuery } from 'gatsby';
import { graphql } from 'gatsby';

export default function useSiteMetadata() {
  const {
    site: { siteMetadata },
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            titleTrademark
            description
          }
        }
      }
    `
  );

  return siteMetadata;
}
