import React from 'react';
import './Navbar.scss';
import { StaticImage } from 'gatsby-plugin-image';
import useSiteMetadata from '../../hooks/useSiteMetadata';

export default function Navbar() {
  const siteMetadata = useSiteMetadata();

  return (
    <nav className="Navbar">
      <div className="Navbar__inner">
        <div className="Navbar__left">
          <StaticImage
            placeholder="blurred"
            src="../../images/icon.png"
            alt="Logo"
            className="Navbar__left__logo"
            width={80}
          />

          <h1 className="Navbar__left__title">{siteMetadata.title}</h1>
        </div>

        {/* <div className="Navbar__right">Navbar links</div> */}
      </div>
    </nav>
  );
}
