import React from 'react';
import './Layout.scss';
import Navbar from '../Navbar';
import useScrollToTopOnMount from '../../hooks/useScrollToTopOnMount';

export default function Layout({ children }) {
  useScrollToTopOnMount();

  return (
    <div className="Layout">
      <Navbar />
      <div className="Layout__children">{children}</div>
      <footer className="Layout__footer">Layout Footer</footer>
    </div>
  );
}
